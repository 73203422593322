// $(document).ready(function(){
//
// window.setTimeout( hideTagFn, 1500);
//
// function hideTagFn(){
//
//    $('.loading-content').hide();
// }
//
// // WORKS
// 	if($('.slider-works .slider').length > 0){
// 		var productSlider = $('.slider-works .slider').owlCarousel({
// 			    loop: true,
// 			    autoplay: true,
// 			    margin: 0,
// 			    navText: ['<i class="icon-left">', '<i class="icon-right">'],
// 				autoplayTimeout: 2800,
// 				autoplayHoverPause: false,
// 				responsive:{
// 			        0: {
// 			            items: 3,
// 			        },
// 			        576:{
// 			            items: 3,
// 			        },
// 			        600:{
// 			            items: 4,
// 			        },
// 			        768:{
// 			            items: 5,
// 			        },
// 			        992:{
// 			            items: 4,
// 			        },
// 			        1200:{
// 			            items: 5,
// 			        },
// 			        1400:{
// 			            items: 6,
// 			        },
// 			        1900:{
// 			            items: 6,
// 			        },
// 			        2200:{
// 			            items: 10,
// 			        }
// 				}
// 		})
//
// 	}
// // TEAM
// 	if($('.team .slider').length > 0){
// 		var productSlider = $('.team .slider').owlCarousel({
// 			    loop: true,
// 			    autoplay: true,
// 			    margin: 0,
// 				autoplayTimeout: 2800,
// 				autoplayHoverPause: false,
// 				responsive:{
// 			        0: {
// 			            items: 2,
// 			        },
// 			        576:{
// 			            items: 2,
// 			        },
// 			        600:{
// 			            items: 3,
// 			        },
// 			        768:{
// 			            items: 3,
// 			        },
// 			        992:{
// 			            items: 4,
// 			        },
// 			        1200:{
// 			            items: 5,
// 			        },
// 			        1400:{
// 			            items: 5,
// 			        }
// 				}
// 		})
//
// 	}
//
// // HEADER: MOBILE MENU
//
// 	var headerMenuButton = $('.mobile-menu');
// 	var headerMenu = $('.mobile-menu-toggle');
// 	var headerMenuActive = false;
//
// 	var collapseButton = $('.hamburger');
// 	var collapse = $('.hamburger');
// 	var collapseActive = false;
//
// 	headerMenuButton.click(function(){
// 		if(headerMenu.hasClass('open')){
// 			headerMenu.removeClass('open')
// 			headerMenuActive = false;
// 		}else{
// 			headerMenu.addClass('open')
// 			headerMenuActive = true;
// 		}
// 		if(collapse.hasClass('is-active')){
// 			collapse.removeClass('is-active')
// 			collapseActive = false;
// 		}else{
// 			collapse.addClass('is-active')
// 			collapseActive = true;
// 		}
// 	})
//
//
// 	$(document).on('click', function(e){
// 		if(headerMenuActive){
// 			if(!headerMenuButton.is(e.target) && headerMenuButton.has(e.target).length === 0 && !headerMenu.is(e.target) && headerMenu.has(e.target).length === 0){
// 				headerMenu.removeClass('show');
// 				headerMenuActive = false;
// 			}
// 		}
// 	})
//
// // HEADER MOBILE: ACCORDION
// 	var header_MobileMenu_accordion = $('header .mobile-menu-toggle .item-multiple a');
// 	var header_MobileMenu_accordionToggle = $('header .mobile-menu-toggle .dropdown');
//
// 	header_MobileMenu_accordion.click(function () {
// 	    if ($(this).hasClass('active')) {
// 	        $(this).removeClass('active').next(header_MobileMenu_accordionToggle).slideUp();
// 	    } else {
// 	        header_MobileMenu_accordion.removeClass('active');
// 	        $(header_MobileMenu_accordionToggle).slideUp();
// 	        $(this).addClass('active').next(header_MobileMenu_accordionToggle).slideDown();
// 	    }
// 	});
//
//
//
// $(".tab-content").hide();
// $(".tab-content:first").show();
// $(".tab-con .tab:first").addClass("active");
// $(".tab-con .tab").click(function(){
//   $(".tab-con .tab").removeClass("active");
//   $(this).addClass("active");
//   $(".tab-content").hide();
//   var tab = $(this).index();
//   $(".tab-content:eq("+tab+")").fadeIn();
// });
//
//
// $('.faq .content .item .quest').click(function () {
//     if ($(this).hasClass('active')) {
//         $(this).removeClass('active').next('.faq .content .item .panel').slideUp();
//     } else {
//         $('.faq .content .item .quest').removeClass('active');
//         $('.faq .content .item .panel').slideUp();
//         $(this).addClass('active').next('.faq .content .item .panel').slideDown();
//     }
// });
//
// });
//
